import { Field, Formik } from "formik";
import React from "react";
import { FC } from "react";
import { Modal } from "react-bootstrap";
import SearchPageService from "../../services/search-page/search-page-service";
import { ICardValidationModalProps } from "../../types/propTypes";
import { CardValidationSchema } from "../../validation/station-details/station-details-validation";
import { Common } from "../../utils/constants";
import { Form } from "react-bootstrap";
import StandardResponse from "../../models/standard-response";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

const searchPageService = new SearchPageService();

const intialValues = {
  card_number: "",
};

const CardValidationModal: FC<ICardValidationModalProps> = (props) => {
  const { t, showModal, onHide } = props;
  const { code } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const inputData = {
      card_number: values.card_number,
      station_code: code,
    };
    await searchPageService
      .validateCard(inputData)
      .then((res: StandardResponse<any>) => {
        if (res.Data && res.Data.connector_id && res.Data.invoice_id && code) {
          localStorage.setItem("SESSION_TOKEN", res.Data.token);
          navigate(
            `/charge-station/${code}/${res.Data.connector_id}/${res.Data.invoice_id}/charge`
          );
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
  };

  return (
    <>
      <Modal show={showModal} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <> {t("stationDetails.modal.header")}</>
          </Modal.Title>
        </Modal.Header>

        <Formik
          enableReinitialize
          initialValues={intialValues}
          onSubmit={handleSubmit}
          validationSchema={CardValidationSchema()}
        >
          {(props) => {
            const { handleChange, handleSubmit, values, setFieldTouched } =
              props;

            return (
              <form
                className="form-primary-info"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Modal.Body>
                  <div className="validate-card-message">
                    <span>
                      {t<string>("stationDetails.modal.validateCardMessage")}
                    </span>
                  </div>

                  <div className="col-12 p-0">
                    <Field type="text" name="card_number">
                      {({
                        field: { ...fields },
                        form: { touched, errors },
                      }) => (
                        <Form.Group
                          className={
                            values.card_number
                              ? "form-group value-exists"
                              : "form-group"
                          }
                        >
                          <Form.Label className={"float-label"}>
                            {t<string>("stationDetails.modal.card_number")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="card_number"
                            name="card_number"
                            isInvalid={Boolean(
                              touched[fields.name] && errors[fields.name]
                            )}
                            maxLength={4}
                            placeholder={t("stationDetails.modal.card_number")}
                            value={
                              props.values.card_number !== null
                                ? props.values.card_number
                                : ""
                            }
                            onChange={(e) => {
                              const regex =
                                Common.RegularExpression
                                  .DigitsRegularExpression;
                              if (regex.test(e.target.value)) {
                                handleChange(e);
                              }
                            }}
                            onBlur={() => {
                              setFieldTouched("card_number", true);
                            }}
                            className="card-number"
                          />
                          {touched[fields.name] &&
                            errors[fields.name] &&
                            errors[fields.name] !== "required" && (
                              <Form.Control.Feedback
                                className="pb-0 error invalid-feedback d-block"
                                type="invalid"
                              >
                                {errors[fields.name]}
                              </Form.Control.Feedback>
                            )}
                        </Form.Group>
                      )}
                    </Field>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <div className="btn-block clearfix ModelBtn">
                    <button
                      type="submit"
                      className="btn btn-primary mr-2 mb-2"
                      title={t("stationDetails.modal.submit")}
                    >
                      <span>{t<string>("stationDetails.modal.submit")}</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary mb-2"
                      title={t("stationDetails.modal.cancel")}
                      onClick={() => onHide()}
                    >
                      <span>{t<string>("stationDetails.modal.cancel")}</span>
                    </button>
                  </div>
                </Modal.Footer>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default CardValidationModal;
