import React, { useEffect } from "react";
import { FC } from "react";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { IAuthoriseSessionProps } from "../../types/propTypes";
import ChargingService from "../../services/charging/charging-service";
import "./style.css";
import StandardResponse from "../../models/standard-response";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const chargingService = new ChargingService();
const AuthoriseSession: FC<IAuthoriseSessionProps> = (props) => {
  const { t } = props;
  const navigate = useNavigate();
  const { invoice_id } = useParams();

  useEffect(() => {
    const token = localStorage.getItem("SESSION_TOKEN");
    if (!token) {
      navigate("");
    }
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: any) => searchParams.get(prop),
    });

    if (params.paymentRetry && params.paymentRetry === "true") {
      props.openChargingProgressPage(params.paymentRetry);
    } else {
      const getChargingAuthorisationStatus = async () => {
        const inputData = {
          invoice_id,
          token,
        };
        await chargingService
          .getChargingAuthorisationStatus(inputData)
          .then((res: StandardResponse<any>) => {
            if (
              res.Data &&
              res.Data.status &&
              res.Data.status.code === "ACTIVE"
            ) {
              setTimeout(() => {
                props.openChargingProgressPage(false, false);
              }, 2000);
            } else if (
              res.Data &&
              res.Data.status &&
              res.Data.status.code === "COMPLETED"
            ) {
              props.openChargingProgressPage(false, true);
            }
            if (
              res.Data &&
              res.Data.status &&
              res.Data.status.code === "INVALID"
            ) {
              toast.error(t<string>("chargingProgress.label.unableToConnect"), {
                autoClose: 10000,
              });
              navigate("/");
            }
          })
          .catch((m) => {
            if (m.response && m.response.data && m.response.data.message) {
              toast.error(m.response.data.message);
            } else {
              toast.error(m.toString());
            }
          });
      };

      if (token && invoice_id) {
        getChargingAuthorisationStatus();
        const interval = setInterval(async () => {
          await getChargingAuthorisationStatus();
        }, 10000);
        return () => {
          clearInterval(interval);
        };
      } else {
        toast.error(t<string>("authoriseSession.common.error"));
        navigate("/");
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t<string>("authoriseSession.title")}</title>
      </Helmet>
      <div className="authorise-session">
        <div className="charge-auth-img-div">
          <img
            className="charge-auth-img"
            src={require("../../assets/images/ic_charge_auth_icon.png")}
            alt="charge auth"
          />
        </div>
        <div className="info-text">
          <span>{t<string>("authoriseSession.info")}</span>
        </div>
        <div className="loader-div">
          <Spinner animation={"border"} />
        </div>
        <div className="instructions">
          <div className="instruction-title">
            <h6 className="sub-title">
              {t<string>("authoriseSession.nextSteps")}
            </h6>
          </div>
          <div className="row no-gutters justify-content-between instruction-card-wrapper">
            <div className="col-12 instruction-card">
              <div className="instruction-card-header">
                <div className="number-circle">1</div>
              </div>
              <div className="instruction-body">
                <div className="instruction-img-div col-xs-3">
                  <img
                    src={require("../../assets/images/ic_plug_in_very_small_icon.png")}
                    className="instruction-img"
                    alt="charger"
                  />
                </div>
                <div className="instruction-text-div col-xs-9">
                  {t<string>("authoriseSession.plugInVehicle")}
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters justify-content-between instruction-card-wrapper pb-30p">
            <div className="col-12 instruction-card">
              <div className="instruction-card-header">
                <div className="number-circle">2</div>
              </div>
              <div className="instruction-body">
                <div className="instruction-img-div col-xs-3">
                  <img
                    src={require("../../assets/images/ic_tea_cup_icon.png")}
                    className="instruction-img"
                    alt="tea cup"
                  />
                </div>
                <div className="instruction-text-div col-xs-9">
                  {t<string>("authoriseSession.chargeSession")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AuthoriseSession;
