import React, { useEffect, useState } from "react";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { IPaymentSuccessProps } from "../../types/propTypes";
import PaymentService from "../../services/payment/payment-service";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StandardResponse from "../../models/standard-response";
import { ITransactionDetails } from "../../models/payment";
import "./style.css";

const paymentService = new PaymentService();
const PaymentSuccess: FC<IPaymentSuccessProps> = (props) => {
  const { t } = props;
  const { code, invoice_id, connector_id } = useParams();
  const navigate = useNavigate();
  const [transactionDetails, setTransactionDetails] =
    useState<ITransactionDetails | null>(null);
  const [showMessage, setShowMessage] = useState<boolean | null>(false);

  useEffect(() => {
    const getTransactionDetails = async () => {
      await paymentService
        .getTransactionDetails(invoice_id)
        .then((res: StandardResponse<ITransactionDetails>) => {
          if (res.Data && Object.keys(res.Data).length !== 0) {
            setTransactionDetails(res.Data);
            if (res.Data && !res.Data.error_message) {
              getToken();
            }
          } else {
            toast.error(t<string>("authoriseSession.common.error"));
            navigate("/");
          }
        })
        .catch((m) => {
          if (m.response && m.response.data && m.response.data.message) {
            toast.error(m.response.data.message);
          } else {
            toast.error(m.toString());
          }
        });
    };

    const getToken = async () => {
      const inputData = {
        invoice_id,
        station_code: code,
      };
      await paymentService
        .getToken(inputData)
        .then((res: StandardResponse<string>) => {
          if (res.Data) {
            localStorage.setItem("SESSION_TOKEN", res.Data);
            setShowMessage(true);
            setTimeout(() => {
              navigate(
                `/charge-station/${code}/${connector_id}/${invoice_id}/charge`
              );
            }, 5000);
          }
        })
        .catch((m) => {
          if (m.response && m.response.data && m.response.data.message) {
            toast.error(m.response.data.message);
          } else {
            toast.error(m.toString());
          }
          navigate("/");
        });
    };

    getTransactionDetails();
  }, []);

  const backbtnclick = () => {
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("paymentSuccess.title")}</title>
      </Helmet>
      <div className="card-body text-center payment-success">
        <div className="row min-vh-50 align-items-center">
          <div className="col">
            <div className="mesg-box text-center">
              {transactionDetails?.error_message ? (
                <>
                  <img
                    src={require("../../assets/images/error-icon.svg").default}
                    alt="error"
                  />
                  <h2 className="error-color">
                    {t<string>("paymentSuccess.unableToProceed")}
                  </h2>
                </>
              ) : (
                <>
                  <img
                    src={require("../../assets/images/success.svg").default}
                    alt=""
                  />
                  <h2 className="text-success">
                    {t<string>("paymentSuccess.label")}
                  </h2>
                  <p>{t<string>("paymentSuccess.Successlabel")}</p>
                </>
              )}
            </div>
            {transactionDetails?.error_message && (
              <>
                <div className="mt-3 error-color">
                  <p className="error-message">
                    {transactionDetails?.error_message}
                  </p>
                </div>
                <div className="clearfix mt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => backbtnclick()}
                  >
                    <span>{t<string>("paymentFail.button.back")}</span>
                  </button>
                </div>
              </>
            )}

            {transactionDetails &&
            !transactionDetails?.error_message &&
            transactionDetails.amount ? (
              <ul className="transaction-details">
                <li>
                  <span className="amount-span">
                    {t<string>("paymentSuccess.amount")}
                  </span>
                  <strong>£{transactionDetails.amount}</strong>
                </li>
              </ul>
            ) : (
              <></>
            )}
            {showMessage && (
              <div className="info-text">
                <span>{t<string>("paymentSuccess.redirectInfo")}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentSuccess;
